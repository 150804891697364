import React from 'react';
import YouTube from 'react-youtube';

const VideoCard = ({ link, epiNo, label }) => {
  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };
  return (
    <div className="flex flex-column item rounded overflow-hidden m-4" data-value={epiNo}>
      <a
        href={`https://www.youtube.com/watch?v=${link}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => openInNewTab(`https://www.youtube.com/watch?v=${link}`)}
      />
      <YouTube videoId={link} />
      <a
        className="mt-2 font-bold "
        rel="noreferrer"
        target="_blank"
        href={`https://www.youtube.com/watch?v=${link}`}
      >
        <span className="text-blue">{label}</span>
      </a>
    </div>
  );
};

export default VideoCard;
