import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
// import { faRunning, faBasketballBall, faTableTennis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import khelesanandEnglishLogo from '../../../src/assets/img/logo/Kh3.png';
import khelesanandGujratiLogo from '../../../src/assets/img/logo/Kh4.png';

import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

const KheloFooter = () => {
  const [currentImg, setCurrentImg] = useState(khelesanandGujratiLogo);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImg(
        currentImg === khelesanandGujratiLogo ? khelesanandEnglishLogo : khelesanandGujratiLogo
      );
    }, 4000);
    return () => clearTimeout(timer);
  }, [currentImg]);
  return (
    <div className="md:pt-0 pt-4 bg-gradient-to-r from-blue-KhGuj1  to-blue-KhGuj2">
      <div className="w-full h-full flex flex-col py-3 px-3 min-h-60 text-sm md:text-base max-w-screen-xl text-white items-center gap-4 md:gap-0 mx-auto">
        {/* <div className="w-full flex md:justify-start justify-center md:py-7 py-0">
          <div className="md:flex w-full justify-evenly items-start">
            <div className="flex w-full gap-4 justify-center flex-wrap px-2 md:px-48">
              {Object.keys(routes).map((route, i) => (
                <Link key={i} to={`/micro-site/${tournamentId}/landing/${routes[route]}`}>
                  <h1 className="text-orange-kheloOrange font-bold font-xs">{route}</h1>
                </Link>
              ))}
            </div>
          </div>
        </div> */}
        <div className="flex mb-2 pt-6">
          <Link
            className="text-white ignore-style flex flex-col gap-2 "
            // className="text-white ignore-style flex flex-col gap-2 border-r-2"
            to={`/`}
          >
            <img src={currentImg} className="h-14 md:h-20 mr-2" />
          </Link>
          {/* <Link
            className="text-white ignore-style flex flex-col gap-2"
            to={`/micro-site/${tournamentId}/landing/overview`}
          >
            <img src={poweredBy} className="h-14 md:h-20 ml-2" />
          </Link> */}
        </div>
        <div className="flex justify-center items-center gap-4 flex-col w-full h-full md:mt-2">
          <div className={`flex gap-8 md:mt-4 md:pt-0.5 justify-center`}>
            <a
              href="https://www.instagram.com/sfanow/"
              target={'_blank'}
              className="text-white ignore-style"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} className="h-4 md:h-6" />
            </a>
            <a
              href="https://www.facebook.com/sfanow"
              target={'_blank'}
              className="text-white ignore-style"
              rel="noreferrer"
            >
              {' '}
              <FontAwesomeIcon icon={faFacebookF} className="h-4 md:h-6" />
            </a>
            <a
              href="https://in.linkedin.com/company/sfaplay"
              target={'_blank'}
              rel="noreferrer"
              className="text-white ignore-style"
            >
              {' '}
              <FontAwesomeIcon icon={faLinkedin} className="h-4 md:h-6" />
            </a>
            <a
              href="https://twitter.com/sfanow"
              target={'_blank'}
              rel="noreferrer"
              className="text-white ignore-style"
            >
              {' '}
              <FontAwesomeIcon icon={faTwitter} className="h-4 md:h-6" />
            </a>
          </div>

          {/* <div className="w-fit flex gap-3 text-xxs md:text-sm text-center md:text-left">
            <a
              className="text-white underline font-bold ignore-style"
              href="privacy-policy"
              // target="_blank"
              rel="noreferrer"
            >
              PRIVACY POLICY
            </a>
          </div> */}

          <div className="text-xxs md:text-sm text-center">
            <p>Powered by SFAPLAY.COM</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KheloFooter;
