import React from 'react';
import VideoCard from '../VideoCard';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const VIDEOS_LINKS = [
  'PrP6yrcurZQ',
  'Pe0xtSGN-xM',
  'h2f-h5d9g8g',
  'YG_l_q_iZzM',
  'fX0ZBu5J9R4',
  '_HRBDE8bPwE',
  'fhUFnXMsvXY',
  'QDQZENxnueQ',
  'JhiVNZBFfeE',
  'shToQeDTcbU',
  'cxmI61uLdNM',
  'zZPKk9j0X6U',
  'AjdRBXPBSjw',
  'VWEmH4mglZw',
];

const YOUTUBE_LABELS = [
  '𝐓𝐡𝐚𝐧𝐤 𝐲𝐨𝐮 𝐀𝐦𝐝𝐚𝐯𝐚𝐝!',
  'TABLE TENNIS STORY',
  'Chasing Glory: Hyderabad Tennis Trio',
  'SFA: Empowering Youth Through Sports',
  'Ab Jeetega India!',
  'Coach Ronald Simms Jr (Boxing)',
  "Swimming Star Shagun's Golden Victory",
  'Chasing Glory : Humility In Victory',
  'Nikhil Jeena | Air-pistol shooter',
  'Siraj Athlete Interview',
  '𝐒𝐨𝐚𝐫𝐢𝐧𝐠 𝐭𝐨 𝐠𝐫𝐞𝐚𝐭 𝐡𝐞𝐢𝐠𝐡𝐭𝐬! ',
  'Badminton Buddies',
  'The Parents & Teachers',
  'Game On! Sumit Richhariya Winning',
];
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  720: { items: 2 },
  1024: { items: 3 },
  1200: { items: 3 },
  1500: { items: 4 },
};

const YouTubeVideosList = () => {
  const Items = VIDEOS_LINKS.map((link, i) => {
    return <VideoCard key={i} link={link} epiNo={i + 1} label={YOUTUBE_LABELS[i]} />;
  });
  return (
    <>
      <div className="text-center mt-5">
        <h1 className="font-bold  text-base  uppercase lg:text-2xl inline-block mb-4 text-orange-500">
          YOUTUBE
        </h1>
      </div>
      <div>
        <AliceCarousel
          preservePosition
          mouseTracking
          items={Items}
          responsive={responsive}
          controlsStrategy="alternate"
        />
      </div>
    </>
  );
};

export default YouTubeVideosList;
