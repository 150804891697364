import React from 'react';

const JioVideoCard = ({ link, epiNo, label, imgSrc }) => {
  const handleDivClick = (url) => {
    window.open(url, '_blank');
  };
  return (
    <div
      onClick={() => handleDivClick(link)}
      className="flex flex-column bg-cover bg-center item rounded overflow-hidden cursor-pointer w-64 h-70 border border-gray-300 m-4"
      data-value={epiNo}
    >
      <img src={imgSrc} alt="Image" className="w-full h-40 object-cover mb-4" />
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => window.open(link, '_blank')}
      />
      <a className="m-2 font-bold " href={link} target="_blank" rel="noreferrer">
        <span className="text-blue">{label}</span>
      </a>
    </div>
  );
};

export default JioVideoCard;
