import KheloFooter from 'micro-site/Components/KheloFooter';
import KheloHeader from 'micro-site/Components/KheloHeader/KheloHeader';
import { useEffect, useState } from 'react';
import MobileVideosBanner from '../../../assets/img/KheleSanandTiles/Vid1.jpg';
import DeskVideosBanner from '../../../assets/img/KheleSanandTiles/Vid2.jpg';
import JioVideosList from './JioList';
import YouTubeVideosList from './YoutubeList';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom';

const Videos = () => {
  const routes = useRouteMatch();

  console.log('routes', routes);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div>
      {routes.path === '/about-us' && <KheloHeader />}
      <div>
        <img src={isMobile ? MobileVideosBanner : DeskVideosBanner} alt={''} className="w-full " />
      </div>
      <div className="p-6">
        <JioVideosList />
        <div className="flex justify-center ">
          <hr className="my-6 border-t-2 border-gray-300 w-full mx-0" />
        </div>
        <YouTubeVideosList />
        <div className="flex justify-center ">
          <hr className="my-6 border-t-2 border-gray-300 w-full mx-0" />
        </div>
        {/* <InstaVideosList /> */}
      </div>
      {routes.path === '/about-us' && <KheloFooter />}
    </div>
  );
};

export default Videos;
