import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import JioVideoCard from '../JioCard';
import CurtainRaiser from '../../../../assets/img/KheleSanandTiles/CurtainRaiser.png';
import UttarakhandPart1 from '../../../../assets/img/KheleSanandTiles/UttarakhandPart1.png';
import UttarakhandPart2 from '../../../../assets/img/KheleSanandTiles/UttarakhandPart2.png';
import UttarakhandPart3 from '../../../../assets/img/KheleSanandTiles/UttarakhandPart3.png';
import UttarakhandPart4 from '../../../../assets/img/KheleSanandTiles/UttarakhandPart4.png';
import IndorePart1 from '../../../../assets/img/KheleSanandTiles/IndorePart1.png';
import IndorePart2 from '../../../../assets/img/KheleSanandTiles/IndorePart2.png';
import IndorePart3 from '../../../../assets/img/KheleSanandTiles/IndorePart3.png';
import IndorePart4 from '../../../../assets/img/KheleSanandTiles/IndorePart4.png';
import HyderabadPart1 from '../../../../assets/img/KheleSanandTiles/HyderabadPart1.png';
import HyderabadPart2 from '../../../../assets/img/KheleSanandTiles/HyderabadPart2.png';
import HyderabadPart3 from '../../../../assets/img/KheleSanandTiles/HyderabadPart3.png';
import HyderabadPart4 from '../../../../assets/img/KheleSanandTiles/HyderabadPart4.png';
import PunePart1 from '../../../../assets/img/KheleSanandTiles/PunePart1.png';
import PunePart2 from '../../../../assets/img/KheleSanandTiles/PunePart2.png';
import PunePart3 from '../../../../assets/img/KheleSanandTiles/PunePart3.png';
import PunePart4 from '../../../../assets/img/KheleSanandTiles/PunePart4.png';
import MumbaiPart1 from '../../../../assets/img/KheleSanandTiles/MumbaiPart1.png';
import MumbaiPart2 from '../../../../assets/img/KheleSanandTiles/MumbaiPart2.png';
import MumbaiPart3 from '../../../../assets/img/KheleSanandTiles/MumbaiPart3.png';
import MumbaiPart4 from '../../../../assets/img/KheleSanandTiles/MumbaiPart4.png';
import JaipurPart1 from '../../../../assets/img/KheleSanandTiles/JaipurPart1.png';
import JaipurPart2 from '../../../../assets/img/KheleSanandTiles/JaipurPart2.png';
import JaipurPart3 from '../../../../assets/img/KheleSanandTiles/JaipurPart3.png';
import JaipurPart4 from '../../../../assets/img/KheleSanandTiles/JaipurPart4.png';

const JIO_IMAGES = [
  CurtainRaiser,
  UttarakhandPart1,
  UttarakhandPart2,
  UttarakhandPart3,
  UttarakhandPart4,
  IndorePart1,
  IndorePart2,
  IndorePart3,
  IndorePart4,
  HyderabadPart1,
  HyderabadPart2,
  HyderabadPart3,
  HyderabadPart4,
  PunePart1,
  PunePart2,
  PunePart3,
  PunePart4,
  MumbaiPart1,
  MumbaiPart2,
  MumbaiPart3,
  MumbaiPart4,
  JaipurPart1,
  JaipurPart2,
  JaipurPart3,
  JaipurPart4,
];
const VIDEOS_LINKS = [
  'https://www.jiocinema.com/sports/curtain-raiser/3865141',
  'https://www.jiocinema.com/sports/uttarakhand-part-1/3866026',
  'https://www.jiocinema.com/sports/uttarakhand-part-2/3866027',
  'https://www.jiocinema.com/sports/uttarakhand-part-3/3866028',
  'https://www.jiocinema.com/sports/uttarakhand-part-4/3866029',
  'https://www.jiocinema.com/sports/indore-part-1/3867085',
  'https://www.jiocinema.com/sports/indore-part-2/3867083',
  'https://www.jiocinema.com/sports/indore-part-3/3867084',
  'https://www.jiocinema.com/sports/indore-part-4/3867086',
  'https://www.jiocinema.com/sports/hyderabad-part-1/3869500',
  'https://www.jiocinema.com/sports/hyderabad-part-2/3869501',
  'https://www.jiocinema.com/sports/hyderabad-part-3/3869890',
  'https://www.jiocinema.com/sports/hyderabad-part-4/3869892',
  'https://www.jiocinema.com/sports/pune-part-1/3885420',
  'https://www.jiocinema.com/sports/pune-part-2/3885419',
  'https://www.jiocinema.com/sports/pune-part-3/3885421',
  'https://www.jiocinema.com/sports/pune-part-4/3885423',
  'https://www.jiocinema.com/sports/mumbai-part-1/3885401',
  'https://www.jiocinema.com/sports/mumbai-part-2/3885399',
  'https://www.jiocinema.com/sports/mumbai-part-3/3885403',
  'https://www.jiocinema.com/sports/mumbai-part-4/3885402',
  'https://www.jiocinema.com/sports/jaipur-part-1/3889175',
  'https://www.jiocinema.com/sports/jaipur-part-2/3889174',
  'https://www.jiocinema.com/sports/jaipur-part-3/3889176',
  'https://www.jiocinema.com/sports/jaipur-part-4/3889177',
];

const JIO_LABELS = [
  'Curtain Raiser',
  'Uttarakhand Part 1',
  'Uttarakhand Part 2',
  'Uttarakhand Part 3',
  'Uttarakhand Part 4',
  'Indore Part 1',
  'Indore Part 2',
  'Indore Part 3',
  'Indore Part 4',
  'Hyderabad Part 1',
  'Hyderabad Part 2',
  'Hyderabad Part 3',
  'Hyderabad Part 4',
  'Pune Part 1',
  'Pune Part 2',
  'Pune Part 3',
  'Pune Part 4',
  'Mumbai Part 1',
  'Mumbai Part 2',
  'Mumbai Part 3',
  'Mumbai Part 4',
  'Jaipur Part 1',
  'Jaipur Part 2',
  'Jaipur Part 3',
  'Jaipur Part 4',
];
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  720: { items: 2 },
  1024: { items: 3 },
  1200: { items: 4 },
  1500: { items: 5 },
};

const JioVideosList = () => {
  const Items = VIDEOS_LINKS.map((link, i) => {
    return (
      <JioVideoCard
        key={i}
        link={link}
        epiNo={i + 1}
        label={JIO_LABELS[i]}
        imgSrc={JIO_IMAGES[i]}
      />
    );
  });
  return (
    <>
      <div className="text-center mt-5">
        <h1 className="font-bold  text-base  uppercase lg:text-2xl inline-block mb-4 text-orange-500">
          SFA CHAMPIONSHIPS ON JIO CINEMA
        </h1>
      </div>
      <div>
        <AliceCarousel
          preservePosition
          mouseTracking
          items={Items}
          responsive={responsive}
          controlsStrategy="alternate"
        />
      </div>
    </>
  );
};

export default JioVideosList;
