import { useState, useEffect } from 'react';
import khelesanandEnglishLogo from '../../../assets/img/logo/Kh3.png';
import khelesanandGujratiLogo from '../../../assets/img/logo/Kh4.png';
const HeaderLogo = () => {
  const [currentImg, setCurrentImg] = useState(khelesanandGujratiLogo);
  // const isLg = useBreakPoint('lg');
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentImg(
        currentImg === khelesanandGujratiLogo ? khelesanandEnglishLogo : khelesanandGujratiLogo
      );
    }, 4000);
    return () => clearTimeout(timer);
  }, [currentImg]);
  return (
    <div>
      <img src={currentImg} className={'h-14 hidden lg:inline-block cursor-pointer'} alt={''} />
      {/* <img src={KheloIndiaLogo} className={'h-12 lg:hidden cursor-pointer'} alt={''} /> */}
    </div>
  );
};

export default HeaderLogo;
