import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import Sports from './Sports';
import Results from './Results';
import Schedule from './Schedule';
import DrawsAndSchedules from './DrawsAndSchedule';
import Attractions from './Attractions';
import Gallery from './Gallery';
import Reconciliation from './Reconciliation';
import Embed from './Embed';
import JotForm from './JotForm/JotFormEmbed';
import CustomSwitch from 'components/routes/CustomSwitch';
import Mascot from './Mascot';
import Anthem from './Anthem';
import PrivacyPolicy from './PrivacyPolicy';
import AboutUs from './About-us';

import { nanoid } from 'nanoid';
import { useAboutUsHeading } from 'micro-site/queries/hooks';
import Home from './Home';
import { News, DetailedNews } from './News';
import { VenuePage, MainPage } from './Venues';
import SportEventList from './Sports/SportEventList';
import IndividualEvent from './Gallery/components/IndividualEvent';
import FoldersInSection from './Gallery/components/FoldersInSection';
import Videos from './Videos';
// import desktopBanner from '../../assets/img/Banner 2 June-dekstop.png';
// import mobileBanner from '../../assets/img/Banner 2 June-Mobile.png';

export default function index() {
  const match = useRouteMatch('');
  const aboutUs = useAboutUsHeading({ tournament: match?.params?.tournament_id });
  return (
    <div className="bg-white">
      <CustomSwitch>
        <Route path={`${match.path}/schedule`}>
          <Schedule />
        </Route>
        <Route exact path={`${match.path}/media`} component={News} />
        <Route exact path={`${match.path}/media/:mediaId`} component={DetailedNews} />
        <Route exact path={`${match?.path}/venues/:city/:venueName`} component={VenuePage} />
        <Route exact path={`${match?.path}/venues/:city/`} component={MainPage} />
        <Route exact path={`${match?.path}/venues`} component={MainPage} />
        <Route exact path={`${match?.path}/about-us`} component={Videos} />
        <Route exact path={`${match?.path}/sports/:sport`} component={SportEventList} />
        <Route exact path={`${match?.path}/gallery/:section/:folder`} component={IndividualEvent} />
        {/*
          <Route
            exact
            path={`${match?.url}/gallery/video/:type`}
            render={(props) => <IndividualEvent withVideo {...props} />}
          />
*/}{' '}
        <Route exact path={`${match?.url}/gallery/:section`} component={FoldersInSection} />
        <Route path={`${match?.url}/embed-page/:builderId`}>
          <Embed key={nanoid()} />
        </Route>
        <Route path={`${match.path}/draws-schedules`}>
          <DrawsAndSchedules enabledScheduled={true} />
        </Route>
        <Route path={`${match.path}/mascot`}>
          <Mascot />
        </Route>{' '}
        <Route path={`${match.path}/anthem`}>
          <Anthem />
        </Route>
        <Route path={`${match.path}/privacy-policy`}>
          <PrivacyPolicy />
        </Route>
        {aboutUs?.data?.data?.map((el, i) => (
          <Route path={`${match.path}/${el}`} key={i}>
            <AboutUs type={el} />
          </Route>
        ))}
        {/* <Route path={`${match.url}/landing/about-us`}>
          <AboutUs type={1} />
        </Route>
        <Route path={`${match.url}/landing/ioa`}>
          <AboutUs type={2} />
        </Route>
        <Route path={`${match.url}/landing/kerala-2015`}>
          <AboutUs type={3} />
        </Route> */}
        <Route path={`${match.path}/overview`} component={Home} />
        <Route exact path={`${match?.path}/support`}>
          <div>
            <JotForm src="https://form.jotform.com/221496086051051" />
          </div>
        </Route>
        <Route exact path={`${match?.path}/feedback`}>
          <div className="">
            <JotForm
              src="https://form.jotform.com/221516022078447"
              className="h-80-screen md:min-h-screen w-full "
              styles={{}}
            />
          </div>
        </Route>
        <Route exact path={`${match?.path}/no-show`}>
          <div>
            <JotForm
              src="https://form.jotform.com/221515744400446"
              className="h-80-screen md:min-h-screen w-full "
              styles={{}}
            />
          </div>
        </Route>
        <div className="md:py-8">
          {/* <img src={desktopBanner} className="md:inline-block hidden mb-2" />
          <img src={mobileBanner} className="inline-block md:hidden mb-2" /> */}
          <Route path={`${match.path}/sports`} component={Sports} />
          <Route path={`${match.path}/results`} component={Results} />
          <Route path={`${match.path}/attractions`} component={Attractions} />
          <Route path={`${match.path}/gallery`} component={Gallery} />

          <Route path={`${match.path}/faq`}>
            <Embed customRoute={'03bbb03c2cfa41bbb23a3f515a28040b'} />
          </Route>
          <Route path={`${match.path}/reconciliation`} component={Reconciliation} />
        </div>
      </CustomSwitch>
    </div>
  );
}
