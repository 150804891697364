import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Loader from 'components/Loader';
import FeatureConfigs from 'utils/FeatureConfigs';
import { Capacitor } from '@capacitor/core';
import MyPdfViewer from 'components/PDFviewer';
import MicroSite from 'micro-site/index';
import ExternalEmbed from 'profiles/ExternalEmbed';
import ErrorBoundary from 'ErrorBoundary';
import CustomSwitch from './CustomSwitch';
import ReportEmbed from 'components/ReportEmbed';
import ZohoReport from 'components/ZohoReport';
import Videos from 'micro-site/Landing/Videos';
// Authentication module imports
const SignIn = lazy(() => import(/* webpackPrefetch: true */ 'authentication/SignIn'));
const SignUp = lazy(() => import(/* webpackPrefetch: true */ 'authentication/SignUp'));
const SignUpJot = lazy(() => import(/* webpackPrefetch: true */ 'authentication/signupJotForm'));
const SignUpJotCoach = lazy(() =>
  import(/* webpackPrefetch: true */ 'authentication/signupJotFormCoach')
);
const SignUpJotAthlete = lazy(() =>
  import(/* webpackPrefetch: true */ 'authentication/signupJotFormAthlete')
);
const ForgotPassword = lazy(() =>
  import(/* webpackPrefetch: true */ 'authentication/ForgotPassword')
);
const ResetPassword = lazy(() =>
  import(/* webpackPrefetch: true */ 'authentication/ResetPassword')
);

// Registration module imports
const RegistrationRedirection = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/Redirection')
);
const CoachManageTeam = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/ManageTeam')
);
const UserRegistration = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/Registration')
);
const UserSportEvents = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/SportEvents/index.js')
);
const UserPayment = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/Payment')
);
const UserPaymentSuccess = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/PaymentSuccess')
);
const CoachRegistrationSuccess = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/RegistrationSuccess')
);
const CoachTeamCreation = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/TeamCreation')
);
const InstitutionSportsSelection = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/SportsSelection')
);
const userVerification = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/Verification')
);
const onBoardingPage = lazy(() =>
  import(/* webpackPrefetch: true */ 'registration/TournamentDetails/OnBoarding')
);
// Profiles module imports
const Account = lazy(() => import(/* webpackPrefetch: true */ 'profiles/Account'));
const NotificationList = lazy(() =>
  import(/* webpackPrefetch: true */ 'profiles/user/Notifications')
);
const Profile = lazy(() => import(/* webpackPrefetch: true */ 'profiles/index'));
const BillingAndInformation = lazy(() =>
  import(/* webpackPrefetch: true */ 'profiles/Account/BillingAndInformation')
);
const MatchAnalytics = lazy(() => import(/* webpackPrefetch: true */ 'profiles/MatchAnalytics'));
const NotFoundPage = lazy(() => import(/* webpackPrefetch: true */ 'components/ErrorPage'));
// Act module imports
const Accommodation = lazy(() => import(/* webpackPrefetch: true */ 'act/Accommodation'));
const Collection = lazy(() => import(/* webpackPrefetch: true */ 'act/Collection'));
const Transport = lazy(() => import(/* webpackPrefetch: true */ 'act/Transport'));
const Support = lazy(() => import(/* webpackPrefetch: true */ 'act/Support'));
const Feedback = lazy(() => import(/* webpackPrefetch: true */ 'act/Feedback'));
const DigitalScoring = lazy(() => import(/* webpackPrefetch: true */ 'digital-scoring/index'));
const AutoLogin = lazy(() => import(/* webpackPrefetch: true */ '../../authentication/AutoLogin'));

// Reconciliation imports
const Reconciliation = lazy(() => import(/* webpackPrefetch: true */ 'Reconciliation/index'));

// main
const Main = lazy(() => import(/* webpackPrefetch: true */ 'Main/index'));

export default function Routes() {
  return (
    <ErrorBoundary
      fallbackRender={() => {
        return (
          <NotFoundPage
            title={'500'}
            line1={'Oops! Something Went Wrong'}
            line2={
              "Unfortunately we're having trouble loading the page you are looking for. Please come back in a while."
            }
          />
        );
      }}
    >
      <Router>
        <Suspense fallback={<Loader />}>
          <CustomSwitch>
            {/* Authentication module routes */}
            <Route exact path="/">
              <Main />
            </Route>
            <Route exact path="/micro-site">
              <Redirect to={defaultRedirectTo} />
            </Route>
            <Route path="/public/score" component={DigitalScoring} />
            <Route path="/auto-login" component={AutoLogin} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/about-us" component={Videos} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route path="/update-password" component={ResetPassword} />
            <Route path="/external-embed/*" component={ExternalEmbed} />
            <Route path="/pdf-viewer/*" component={MyPdfViewer} />

            {/* <Route exact path="/signup"> */}
            {/*  <SignUp step={1} /> */}
            {/* </Route> */}
            <Route path="/register/form/:formId" component={SignUpJot} />
            <Route path="/register/coach" component={SignUpJotCoach} />
            <Route path="/register/athlete" component={SignUpJotAthlete} />
            <Route exact path="/register/" component={SignUp} />

            <Route exact path="/register/:step" component={SignUp} />
            <Route exact path="/onboarding" component={onBoardingPage} />
            {/* Reconciliation */}
            <Route exact path="/ng-entries/:tournamentId" component={Reconciliation} />

            {/* Registration Redirection */}
            <ProtectedRoute
              path="/registration/:tournament_id"
              component={RegistrationRedirection}
            />

            {/* Registration module routes */}
            <ProtectedRoute
              exact
              path="/coach/registration/:tournament_id/manage-team"
              component={CoachManageTeam}
            />
            <ProtectedRoute
              exact
              path="/coach/registration/:tournament_id/create-team"
              component={CoachTeamCreation}
            />
            <ProtectedRoute
              exact
              path="/:user/registration/:tournament_id/tournament-form"
              component={UserRegistration}
            />
            <ProtectedRoute
              exact
              path="/:user/registration/:tournament_id/sport-events"
              component={UserSportEvents}
            />
            <ProtectedRoute
              exact
              path="/:user/registration/:tournament_id/payment"
              component={UserPayment}
            />
            <ProtectedRoute
              exact
              path="/:user/registration/:tournament_id/payment-success"
              component={UserPaymentSuccess}
            />
            <ProtectedRoute
              exact
              path="/coach/registration/:tournament_id/registration-success"
              component={CoachRegistrationSuccess}
            />
            <ProtectedRoute
              exact
              path="/institution/registration/:tournament_id/sports-selection"
              component={InstitutionSportsSelection}
            />
            <ProtectedRoute
              exact
              path="/:user/registration/:tournament_id/verification"
              component={userVerification}
            />

            {/* Profiles module routes */}
            <ProtectedRoute exact path="/:user/profiles/account-details" component={Account} />
            <ProtectedRoute
              exact
              path="/:user/profiles/notifications"
              component={NotificationList}
            />
            <ProtectedRoute
              exact
              path="/:user/profiles/billing-details"
              component={BillingAndInformation}
            />
            <ProtectedRoute
              exact
              path={`/:user/profiles/match-analytics/:match_id`}
              component={MatchAnalytics}
            />
            <ProtectedRoute path="/:user/profiles" component={Profile} />

            {/* Act module routes */}
            <ProtectedRoute path="/act/accommodation" component={Accommodation} />
            <ProtectedRoute path="/act/collection" component={Collection} />
            <ProtectedRoute path="/act/transport" component={Transport} />
            <ProtectedRoute path="/support/:tournament_id" component={Support} />
            <Route path="/feedback/:tournament_id" component={Feedback} />

            {/* Default route */}
            <Route path="/micro-site/:tournament_id" component={MicroSite} />
            <Route path="/open-view/:report_id" component={ReportEmbed} />
            <Route path="/reports" component={ZohoReport} />
          </CustomSwitch>
        </Suspense>
      </Router>
    </ErrorBoundary>
  );
}

// Set default redirection path based on the platform
export const defaultRedirectTo = Capacitor.isNativePlatform()
  ? '/'
  : FeatureConfigs.ALLOW_ROUTE_REDIRECTION && window.location.pathname === '/'
  ? '/'
  : '/';
